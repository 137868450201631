import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import uuid from 'react-uuid';
import LabService from 'services/LabService';
import LabCategoryService from 'services/admin/masters/LabCategoryService';
import {
    HvGrid,
    HvInput,
    HvTypography,
    HvDropdown,
    HvCheckBox,
    HvCheckBoxGroup,
    HvDatePicker
} from "@hitachivantara/uikit-react-core";
import PropTypes from 'prop-types';
import RichTextBox from 'components/richTextBox';
import Footer from 'components/footer/Footer';
import { buildQueryParams, getMidnightUTCString } from 'utils/common';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { showLoader } from 'redux/actions/commonActions';
import { showBanner } from 'redux/actions/bannerActions';
import moment from "moment";
import { LAB_AS_A_SERVICE, ON_DEMAND_LAB, PREP_LAB_ONDEMAND } from 'constants/labs';

const GeneralSteps = props => {
    const { masterUrl, labId, currentStep } = props
    const history = useHistory();
    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        prespawn_count: 0,
        max_age: 0,
        is_public: false,
        auto_suspend: false,
        status: "Active",
        category: "",
        tag_description: "On Demand",
        dependent_labs: [],
        lab_type: "On Demand",
        maintenance: false,
        maintenance_end: getMidnightUTCString(),
        co_creation_lab: false,
    });
    const labServiceObj = new LabService();
    const dispatch = useDispatch();
    const labCategoryServiceObj = new LabCategoryService();
    const [dropdownMenuItems, setDropdownMenuItems] = useState([
        { label: "Active", value: "Active", selected: true },
        { label: "In Development", value: "In Development" },
        { label: "Retired", value: "Retired" },

    ])
    const [labdropdownMenuItems, setLabdropdownMenuItems] = useState([
        { label: "On Demand", value: "On Demand", selected: true },
        { label: "Lab As A Service", value: "Lab As A Service" },
        { label: "PREP OnDemand", value: "PREP On Demand" },
        { label: "CoCreation Lab", value: "CoCreation Lab" }
    ])
    const [labCategories, setLabCategories] = useState([]);
    const [showDependentLabs, setShowDependentLabs] = useState(false);
    const [dependentLabs, setDependentLabs] = useState([]);
    const [maintenance, setMaintenance] = useState(false);
    let categories = [];


    const renderCheckBoxGroup = () => {
        return (
            <HvCheckBoxGroup key={uuid()} onChange={(e, value) => {
                createGeneralTabPayload("category", value); setLabCategories(labCategories.map(item => {
                    if (value.includes(item.value)) {
                        return { ...item, checked: true };
                    } else {
                        return { ...item, checked: false };
                    }
                }))
            }} label={<HvTypography>Select Categories</HvTypography>} orientation='vertical'>
                {
                    labCategories.map((item) => {
                        return (
                            <HvCheckBox key={item?.id} label={item.label} value={item.value} checked={item?.checked} />
                        )
                    })
                }
            </HvCheckBoxGroup>
        )



    }

    function removeEmptyAndUndefined(obj) {
        // Filter out empty and undefined values
        const filteredEntries = Object.entries(obj).filter(([key, value]) => {
            return value !== '' && value !== undefined;
        });

        // Reconstruct object from filtered entries
        const filteredObj = Object.fromEntries(filteredEntries);

        return filteredObj;
    }

    const handleGeneralSave = () => {
        const updatedPayload = removeEmptyAndUndefined(initialValues)
        let payload = {
            ...updatedPayload,
            ["tag_description"]: updatedPayload?.lab_type
        }
        if (props.mode === "Add") {
            dispatch(showLoader(true));
            labServiceObj.createLabUrl(payload)
                .then((response) => {
                    if (response && response?.id) {
                        dispatch(showLoader(false));
                        props.setNextStep(currentStep + 1)
                        history.push(`${masterUrl}/edit/${response?.id}`);

                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        else {
            if (props.mode === "Edit") {
                labServiceObj.editLabUrl(labId, payload).then(response => {
                    if (response && response?.id) {
                        props.setNextStep(currentStep + 1)
                    }
                }).catch((err) => console.log(err));
            }
        }

    }
    const createGeneralTabPayload = (key, value) => {
        setInitialValues({
            ...initialValues,
            [key]: value
        });
    }

    useEffect(() => {
        const params = {
            is_deleted: false,
        }
        labCategoryServiceObj.getAllAddLabCategory(buildQueryParams(params)).then((response) => {
            if (response?.results) {
                if (props.mode === "Add") {
                    categories = response?.results.map((item) => {
                        return (
                            {
                                label: item?.name,
                                value: item?.id,
                                checked: false,
                                id: item?.id
                            }
                        )
                    })
                } else {
                    if (props.labData?.category?.length === 0 || props.labData?.category?.length >= 1) {
                        categories = response.results.map(item => {
                            if (props.labData?.category.includes(item?.id)) {
                                return ({
                                    label: item?.name,
                                    value: item?.id,
                                    checked: true,
                                    id: item?.id
                                })
                            } else {
                                return ({
                                    label: item?.name,
                                    value: item?.id,
                                    checked: false,
                                    id: item?.id
                                })
                            }
                        })
                    }
                }

                setLabCategories(categories);
            }

        }).catch(error => {
            console.log(error)
        })
        if (props.mode === "Edit") {
            setLabCategories(labCategories.map(item => {
                if (props.labData?.category.includes(item.value)) {
                    return { ...item, checked: true };
                } else {
                    return item;
                }
            }))
        }

        if (props.mode === "Edit") {
            setInitialValues({
                name: props.labData?.name,
                description: props.labData?.description ? props.labData?.description : "",
                prespawn_count: props.labData?.prespawn_count ? JSON.stringify(props.labData?.prespawn_count) : 0,
                max_age: props.labData?.max_age ? JSON.stringify(props.labData?.max_age) : 0,
                is_public: props.labData?.is_public ? props.labData?.is_public : false,
                auto_suspend: props.labData?.auto_suspend ? props.labData?.auto_suspend : false,
                status: props.labData?.status ? props.labData?.status : "Active",
                tag_description: props.labData?.tag_description ? props.labData?.tag_description : "On Demand",
                lab_type: props.labData?.lab_type ? props.labData?.lab_type : "On Demand",
                dependent_labs: props.labData?.dependent_labs ? props.labData.dependent_labs : [],
                maintenance: props.labData?.maintenance ? props.labData?.maintenance : false,
                maintenance_end: props.labData?.maintenance_end ? props.labData?.maintenance_end : getMidnightUTCString(),
                co_creation_lab: props.labData?.co_creation_lab ? props.labData?.co_creation_lab : false
            })
            setDropdownMenuItems(dropdownMenuItems.map((item) => {
                if (item.value === props.labData?.status) {
                    return { ...item, selected: true }
                } else {
                    return { ...item, selected: false }
                }
            }))
            setLabdropdownMenuItems(labdropdownMenuItems.map((item) => {
                if (item.value === props.labData?.tag_description) {
                    return { ...item, selected: true }
                } else {
                    return { ...item, selected: false }
                }
            }))
            if (props.labData?.dependent_labs?.length >= 0 && props.labData?.lab_type === "Lab As A Service") {
                setShowDependentLabs(true);
                getDependentLabs();
            }
            
        }
    }, [props.labData, props.labData?.category])

    const getDependentLabs = () => {
        let labs = [];
        labServiceObj.getDependentLabs().then((response) => {
            if (response?.data) {
                labs = response?.data.map((item) => {
                    return (
                        {
                            label: item?.name,
                            value: item?.id,
                            id: item?.id,
                            selected: false,
                        }
                    )
                })?.filter((item) => item.id !== props.labData.id)
                setDependentLabs(labs)
                if (props.labData?.dependent_labs.length >= 0) {
                    const result = labs.map(item => {
                        return {
                            id: item.id,
                            selected: props.labData?.dependent_labs?.includes(item.value),
                            label: item.label,
                            value: item.id
                        };
                    });

                    setDependentLabs(result);
                }

            }

        })
    }
    return (
        <form autoComplete='off' onSubmit={(event) => {
            event.preventDefault();
            handleGeneralSave()
        }}>
            <HvGrid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <HvGrid item xs={12} style={{ alignSelf: "center" }}>
                    <HvTypography variant='sTitle'>General</HvTypography>
                </HvGrid>
                <HvGrid item md={6} xs={12}>
                    <HvInput id="name" label={"Name"} aria-label='name' type='text' required placeholder='Enter Lab Name'
                        onChange={(e) => {
                            createGeneralTabPayload('name', e.target.value)
                        }} value={initialValues.name} />
                </HvGrid>
                <HvGrid item md={3} xs={3} xl={2} lg={2} sm={2}>
                    <HvDropdown id={'status'} values={dropdownMenuItems} label={"Status"} onChange={(e) => {
                        createGeneralTabPayload('status', e?.value);
                        setDropdownMenuItems(dropdownMenuItems.map((item) => {
                            if (item.label === e?.label) {
                                return { ...item, selected: true }
                            } else {
                                return { ...item, selected: false }
                            }
                        }))
                    }} />

                </HvGrid>
                <HvGrid item md={3} xs={3} xl={2} lg={2} sm={2}>
                    <HvDropdown id={'labType'} values={labdropdownMenuItems} label={"lab Type"} onChange={(e) => {
                        createGeneralTabPayload('tag_description', e?.value);
                        setLabdropdownMenuItems(labdropdownMenuItems.map((item) => {
                            if (item.label === e?.label) {
                                return { ...item, selected: true }
                            } else {
                                return { ...item, selected: false }
                            }
                        }))
                        if (e?.value === LAB_AS_A_SERVICE) {
                            setShowDependentLabs(true)
                            
                            getDependentLabs()
                        } else {
                           
                            setShowDependentLabs(false)
                        }
                        createGeneralTabPayload('lab_type', e?.value);
                    }
                    } />
                </HvGrid>
                {
                    showDependentLabs ? <HvGrid item md={3} xs={3} xl={2} lg={3} sm={2}>
                        <HvDropdown id={'dependantLabs'} values={dependentLabs} label={"Dependent Labs"} multiSelect={true} onChange={(e) => {
                            createGeneralTabPayload('dependent_labs', e?.map(obj => obj.value));
                            if (e?.length > 0) {
                                setDependentLabs(dependentLabs.map(item1 => {
                                    const match = e?.find(item2 => item1.id === item2.id);
                                    if (match) {
                                        return { ...item1, ...match };
                                    } else {
                                        return { ...item1, selected: false };
                                    }

                                }))
                            } else {
                                setDependentLabs(dependentLabs.map((item1) => {
                                    return (
                                        {
                                            ...item1,
                                            selected: false
                                        }
                                    )
                                }))
                            }

                        }
                        } />
                    </HvGrid> : <></>
                }
                <HvGrid item md={2} xs={2} xl={3} lg={3} sm={2} style={{ alignSelf: "center", paddingTop: "60px" }}>
                    <HvCheckBox label={"Is Public"} onChange={(item) => { createGeneralTabPayload("is_public", item?.target.checked) }} checked={initialValues?.is_public} />
                    <HvCheckBox label={"Auto Suspend"} onChange={(item) => { createGeneralTabPayload("auto_suspend", item?.target.checked) }} checked={initialValues?.auto_suspend} />
                    <HvCheckBox label={"Maintenance"} onChange={(item) => {
                        createGeneralTabPayload("maintenance", item?.target.checked);
                        setMaintenance(item?.target.checked)
                    }} checked={initialValues?.maintenance} />
                </HvGrid>
                <HvGrid item md={3} xs={3} xl={2} lg={2} sm={2}>
                    <HvInput type='number' id={"max_age"} label={"Hard Stop"} onChange={(item) => {
                        createGeneralTabPayload("max_age", item?.target.value)
                    }} value={initialValues?.max_age} />
                </HvGrid>
                <HvGrid item md={3} xs={3} xl={2} lg={2} sm={2}>
                    <HvInput type='number' id={"prespawn_count"} label={"Prespawn Count"}
                        onChange={(item) => {
                            createGeneralTabPayload("prespawn_count", item?.target.value)
                        }} value={initialValues?.prespawn_count} />
                </HvGrid>
                <HvGrid item md={6} xs={3} xl={4} lg={4} sm={3}>
                    {
                        maintenance || initialValues?.maintenance ? <HvDatePicker
                            label="Enter Maintenance End Date"
                            locale="en-US"
                            placeholder="Select date"
                            status="standBy"
                            onChange={(date) => {
                                if (moment(date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")) {
                                    const isoDate = new Date(date);
                                    createGeneralTabPayload("maintenance_end", isoDate.toISOString().substring(0, 19) + "Z")
                                } else {
                                    dispatch(showBanner({
                                        payload: {
                                            showBanner: true,
                                            label: { message: "Invalid  Date. Please select a future or present date." },
                                            variant: "error"
                                        }
                                    }))
                                }

                            }}
                            value={initialValues?.maintenance_end ? new Date(initialValues?.maintenance_end) : new Date()}

                        /> : <></>
                    }
                </HvGrid>
                <HvGrid item md={6} xs={3} xl={12} lg={12} sm={3}>
                    {
                        renderCheckBoxGroup()

                    }

                </HvGrid>
                <HvGrid item xs={12} style={{ alignSelf: "center" }}>
                    <HvTypography variant='sTitle'>Description</HvTypography>
                </HvGrid>
                <HvGrid item xs={12} xl={12} lg={12} md={12} sm={12}>
                    <RichTextBox id={'description'} onChange={(property, html) => {
                        createGeneralTabPayload(property, html);
                    }} property={"description"} content={initialValues?.description} />
                </HvGrid>
            </HvGrid>
            <Footer handleSumbit={(event) => { event?.preventDefault() }} />
        </form>
    );
};

GeneralSteps.propTypes = {
    labData: PropTypes.any
};

export default GeneralSteps;