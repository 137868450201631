import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import { HvTable } from '@hitachivantara/uikit-react-compat';
import {
    HvTypography,
    HvTableContainer, HvTable,
    HvTableHead, HvTableRow,
    HvTableHeader, HvTableBody,
    HvTableCell, HvPagination, useHvPagination, useHvData, HvEmptyState, hvTextColumn, useHvSortBy
} from '@hitachivantara/uikit-react-core';
import { Ban } from '@hitachivantara/uikit-react-icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FileUploadService from 'services/FileUploadService';
import { showBanner } from 'redux/actions/bannerActions';
import { useDispatch } from 'react-redux';
import { buildQueryParams } from 'utils/common';

const Resource = (props) => {
    const [resourceData, setResourceData] = useState([]);
    const [data, setData] = useState(resourceData);
    const [totalResource, setTotalResource] = useState();
    const fileUploadServiceObj = new FileUploadService();
    let numPages;
    let urlParams = {};
    const dispatch = useDispatch();


    const getColumns = () => [
        hvTextColumn({
            Header: "Name",
            accessor: "name",
            cellType: "alpha-numeric",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }}>
                            <HvTypography variant='link' style={{ cursor: "pointer" }} onClick={() => { openResourceInNewTab(cellData?.cell?.row?.original?.url) }}> {cellData?.value}</HvTypography>
                        </div>

                    );
                } else {
                    return (<></>)
                }

            },

        }),

        hvTextColumn({
            Header: "Type",
            accessor: "resources_type",
            cellType: "alpha-numeric"
        }),

    ];

    const columns = useMemo(() => {
        return getColumns();
    }, []);

    const { getTableProps, getTableBodyProps, prepareRow, headers, page, gotoPage, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                defaultColumn: {
                    Cell: ({ value }) => value ?? "—",
                },
                manualPagination: true,
                manualSortBy: true,
                disableCreateExpandButton: true,
                pageCount:totalResource
            },
            useHvSortBy,
            useHvPagination
        );

    useEffect(() => {
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        urlParams["object_id"] = props.objectId;
        urlParams["limit"]=pageSize;
        urlParams["offset"]=pageSize * ((pageIndex + 1) - 1);
        urlParams["ordering"]="-id";
        fileUploadServiceObj.getResourceUrlByLabId(buildQueryParams(urlParams)).then(response => {
            if (response) {
                const { results, count } = response;
                if (results && results.length > 0) {
                    setResourceData(results);
                    setTotalResource(Math.ceil(count / pageSize));
                    const tableData = results?.map((item) => {
                        let output = {}
                        if (!_.isNull(item.file)) {
                            output.type = item.file_type;
                            output.url = item.file
                            output.name = item.name
                            output.resources_type = item.resources_type
                            output.noActions = true
                            return output
                        } else if (!_.isNull(item.url)) {
                            output.type = "URL";
                            output.url = item.url
                            output.name = item.name
                            output.resources_type = item.resources_type
                            output.noActions = true
                            return output
                        }

                    })
                    setData(tableData)
                }
            }
        }).catch(error => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Error while getting resource." },
                    variant: "error"
                }
            }))
        })


    }, [sortBy, pageIndex, pageSize])


    const openResourceInNewTab = (url) => {
        var sanitizeUrl = encodeURI(url);
        window.open(sanitizeUrl, '_blank').focus();
    };

    const useStyles = makeStyles({
        container: {
            display: "flex",
            justifyContent: "space-evenly",
            "&>*": {
                flexGrow: 1,
            },
        },
    });



    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );


    const rowRenderer = (pages) => {
        return pages.map((row, index) => {
            prepareRow(row);

            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                    >
                        {row.cells.map((cell) => (
                            <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </HvTableCell>
                        ))}
                    </HvTableRow>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <HvTableContainer>
                <HvTable
                    {...getTableProps({
                        "aria-rowcount": data.length,
                        caption: "Table Caption",
                    })}
                    style={{
                        width: "100%",
                        position: "relative"
                    }}
                >
                    <HvTableHead>
                        <HvTableRow>
                            {headers.map((col) => (
                                <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                    {col.render("Header")}
                                </HvTableHeader>
                            ))}
                        </HvTableRow>
                    </HvTableHead>
                    <HvTableBody {...getTableBodyProps()}>
                        {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                    </HvTableBody>
                </HvTable>
            </HvTableContainer>
            <HvPagination 
            {...getHvPaginationProps()} />
        </>
    );

};

Resource.propTypes = {
    resourceData: PropTypes.any,
    totalRecords: PropTypes.any,
    objectId: PropTypes.any
}

export default Resource;