import React from "react";
import ROLES from "constants/roles";
import {
  UserList,
  UserForm,
  CategoryList,
  CategoryForm,
  ToolsList,
  ToolsForm,
  LabForm,
  LabList,
  ToolCategoryList,
  ToolCategoryForm,
  GroupList,
  GroupForm,
  VideoCatalogList,
  VideoCatalogForm,
  VideoCategoryList,
  VideoCategoryForm,
  RogueSessionList,
  ActiveSessionList,
  FaqList,
  FaqCategoryForm,
  FaqQuestionForm,
  SupportList,
  SupportQuestionForm,
  FeedbackList,
  FeedbackForm,
  SupportForm,
  DocumentsList,
  DocumentsForm,
  DocumentCategoryList,
  DocumentCategoryForm,
  LabListComp,
} from "containers/admin/masters";

import UserListComp from "containers/admin/masters/users/UserListComp";
import PrivateRoute from "components/PrivateRoute";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "components/PageNotFound";
import Unauthorized from "components/Unauthorized";
import LabDetailView from "containers/newHome/LabDetailView";
import Charts from "components/reportVisualizations/Charts";
import ChartsTop10 from "components/reportVisualizations/ChartsTop10";
import Geography from "components/reportVisualizations/Geography";
import APAC from "components/reportVisualizations/APAC";
import Banner from "components/banner/Banner";
import DynamicBanner from "containers/admin/masters/dynamicBanner/DynamicBanner";

const screenSize = window.screen.width;

const MasterRoutes = ({ match }) =>
  screenSize >= 768 ? (
    <Switch>
      <Route
        path={`${match.url}`}
        exact
        component={() => (
          <PageNotFound
            title="Error : Invalid Master Route"
            description="Please select a valid master"
          />
        )}
      />

      <PrivateRoute
        exact
        path={`${match.url}/userGroups`}
        requiredRoles={[ROLES.System_User]}
        component={Charts}
      />
      <PrivateRoute
        exact
        path={`${match.url}/top10`}
        requiredRoles={[ROLES.System_User]}
        component={ChartsTop10}
      />

      <PrivateRoute
        exact
        path={`${match.url}/geography`}
        requiredRoles={[ROLES.System_User]}
        component={Geography}
      />

      <PrivateRoute
        exact
        path={`${match.url}/users`}
        requiredRoles={[ROLES.System_User]}
        component={UserListComp}
      />
      <PrivateRoute
        exact
        path={`${match.url}/users/add`}
        requiredRoles={[ROLES.System_User]}
        component={UserForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/users/edit/:userId`}
        requiredRoles={[ROLES.System_User]}
        component={UserForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/lab/category`}
        requiredRoles={[ROLES.System_User]}
        component={CategoryList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/lab/category/add`}
        requiredRoles={[ROLES.System_User]}
        component={CategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/lab/category/edit/:categoryId`}
        requiredRoles={[ROLES.System_User]}
        component={CategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools`}
        requiredRoles={[ROLES.System_User]}
        component={ToolsList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools/add`}
        requiredRoles={[ROLES.System_User]}
        component={ToolsForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools/edit/:toolId`}
        requiredRoles={[ROLES.System_User]}
        component={ToolsForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools/category`}
        requiredRoles={[ROLES.System_User]}
        component={ToolCategoryList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools/category/add`}
        requiredRoles={[ROLES.System_User]}
        component={ToolCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/tools/category/edit/:categoryId`}
        requiredRoles={[ROLES.System_User]}
        component={ToolCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentsList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents/add`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentsForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents/edit/:documentId`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentsForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents/category`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentCategoryList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents/category/add`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/documents/category/edit/:categoryId`}
        requiredRoles={[ROLES.System_User]}
        component={DocumentCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/groups`}
        requiredRoles={[ROLES.System_User]}
        component={GroupList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/groups/add`}
        requiredRoles={[ROLES.System_User]}
        component={GroupForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/groups/edit/:groupId`}
        requiredRoles={[ROLES.System_User]}
        component={GroupForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/catalog`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCatalogList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/catalog/add`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCatalogForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/catalog/edit/:catalogId`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCatalogForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/category`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCategoryList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/category/add`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/video/category/edit/:categoryId`}
        requiredRoles={[ROLES.System_User]}
        component={VideoCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/session/rogue`}
        requiredRoles={[ROLES.System_User]}
        component={RogueSessionList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/session/active`}
        requiredRoles={[ROLES.System_User]}
        component={ActiveSessionList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/labs`}
        requiredRoles={[ROLES.System_User]}
        component={LabListComp}
      />
      <PrivateRoute
        exact
        path={`${match.url}/labs/add`}
        requiredRoles={[ROLES.System_User]}
        component={LabForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/labs/edit/:labId`}
        requiredRoles={[ROLES.System_User]}
        component={LabForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/faq`}
        requiredRoles={[ROLES.System_User]}
        component={FaqList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/faq/add/category`}
        requiredRoles={[ROLES.System_User]}
        component={FaqCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/faq/edit/category/:categoryId`}
        requiredRoles={[ROLES.System_User]}
        component={FaqCategoryForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/faq/add/question`}
        requiredRoles={[ROLES.System_User]}
        component={FaqQuestionForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/faq/edit/question/:questionId`}
        requiredRoles={[ROLES.System_User]}
        component={FaqQuestionForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/support`}
        requiredRoles={[ROLES.System_User]}
        component={SupportList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/support/add/question`}
        requiredRoles={[ROLES.System_User]}
        component={SupportQuestionForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/support/edit/question/:questionId`}
        requiredRoles={[ROLES.System_User]}
        component={SupportQuestionForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/feedback`}
        requiredRoles={[ROLES.System_User]}
        component={FeedbackList}
      />
      <PrivateRoute
        exact
        path={`${match.url}/feedback/:feedbackId`}
        requiredRoles={[ROLES.System_User]}
        component={FeedbackForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/feedback/support/:supportId`}
        requiredRoles={[ROLES.System_User]}
        component={SupportForm}
      />
      <PrivateRoute
        exact
        path={`${match.url}/labdetailview/:labId`}
        requiredRoles={[ROLES.System_User, ROLES.User]}
        component={LabDetailView}
      />
      <PrivateRoute
        exact
        path={`${match.url}/apac`}
        requiredRoles={[ROLES.System_User]}
        component={APAC}
      />
      <PrivateRoute
        exact
        path={`${match.url}/banner`}
        requiredRoles={[ROLES.System_User]}
        component={DynamicBanner}
      />
      <Route
        component={() => (
          <PageNotFound
            title="Error : Invalid Master Route"
            description="Please select a valid master"
          />
        )}
      />
    </Switch>
  ) : (
    <Unauthorized />
  );
export default MasterRoutes;
