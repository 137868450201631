import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    activeButton: {
        background: 'background: transparent 0% 0% no-repeat padding-box',
        opacity: 1
    },
    dropdown: {
        '& .HvDropdown-root': {
            width: '225px'
        },
        '& .HvBaseDropdown-header': {
            border: '1px solid #6C6B6B'
        },
        '& .HvListItem-interactive:not(.HvListItem-disabled):not(.HvListItem-selected):hover': {
            backgroundColor: '#1775E01A !important'
        }
    },
    root: {
        '& .HvCard-semanticBar': {
            backgroundColor: '#4D8AC0'
        },
    },
    button: {
        cursor: "pointer",
        textAlign: "inherit",
        backgroundColor: "transparent",
        margin: 0,
        border: 0,
        padding: 0,
        width: "100%",
        "&:focus": {
            outline: "none",
        },
    },
    description: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        paddingTop: "15px",
        paddingLeft:"15px"

    },
    knowMore: {
        padding: '15px',
        cursor: "pointer"
    },
    card: {
        background: '#FBFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #CCCED0',
        borderRadius: '0px 0px 6px 6px',
        opacity: 1,
        height: "100%",
        width: "100%",
        transition: 'transform 0.3s ease-in-out', // Added transition property
        '&:hover': {
          transform: 'scale(1.05)', // Scale up on hover
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
        },
        cursor:"pointer"
    },  
    viewProgressButton: {
        font: 'normal normal 600 14px / 11px Open Sans',
        letterSpacing: '0px',
        textAlign: 'center',
        color: '#2064B4',
        opacity: 1
    },

    subtitleLeft: {
        "& .HvCardHeader-title": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: "210px",
            color: "#414141",
            fontSize: "18px",
            fontFamily: 'Open Sans ,Arial,Helvetica,sans-serif',
            fontWeight: '600',
            lineHeight: '28px',
            letterSpacing: '0.02em'
        }
    },



hvPagination: {
    '& .HvPagination-pageSizeOptions': {
        display: 'contents',
            position: "inherit"
    },

},
hvButtonPrimary: {
    '&.HvButton-primaryGhost': {
        color: '#2064B4',
            backgroundColor: 'var(--toast-white)',
                border: '1px solid #2064B4',
                fontSize: 'var(--uikit-typography-body-fontSize)',
                fontWeight: 'var(--uikit-typography-body-fontWeight)',
    },
    '&.HvButton-primaryGhost:hover': {
        color: '#2064B4',
            backgroundColor: 'var(--toast-white)',
            border: '1px solid #2064B4',
            fontSize: 'var(--uikit-typography-body-fontSize)',
            fontWeight: 'var(--uikit-typography-body-fontWeight)',
    }
},
hvButtonGhost: {
    '&.HvButton-ghost': {
        border: '#2064B4 1px solid'
    },
    '&.HvButton-ghost:hover': {
        border: '#2064B4 1px solid'
    }
},
hvDialog: {
    '& .HvDialog-background': {
        backgroundColor: "#00000000"
    },
    '& .HvDialog-paper': {
        boxShadow : "0 2px 12px rgba(65,65,65,0.12)"
    }
},
hvActionBar: {
    '&.HvActionBar-root': {
        paddingTop: "5px",
            paddingBottom: "5px",
                paddingLeft: "0px",
                    paddingRight: "5px"
    }
},
hvCardButtonText: {
    '&.HvTypography-body': {
        color: "#2064B4"
    }
},
hvPlayVideoButon: {
    '&.HvButton-ghostSVG svg .color0': {
        fill: "#2064B4"
    }
},
hvButtonPrimaryScheduler: {
    '&.HvButton-primary': {
        color: 'var(--toast-black)',
            backgroundColor: '#27d0b287'
    },
    '&.HvButton-primary:hover': {
        color: 'var(--toast-black)',
            backgroundColor: '#27d0b287'
    }
},
hvButtonGhostScheduler: {
    '&.HvButton-primaryGhost': {
        color: 'var(--toast-black)',
        backgroundColor: '#dd9fb7'
    },
    '&.HvButton-primaryGhost:hover': {
        color: 'var(--toast-black)',
        backgroundColor: '#dd9fb7'
    }
},
controlContainer: {
    width: "100%",
        maxWidth: 400,
    },
labelContainer: {
    display: "flex",
        alignItems: "flex-start",
    },
label: {
    paddingBottom: "6px",
    },
inputContainer: {
    width: "inherit",
        display: "flex",
            alignItems: "baseline",
                gap: "5px",
                    "& > *": {
        width: "100%",
            display: "contents"
    },
    "& > *:first-child": {
        marginLeft: 0,

        },
},
input: {
    flexGrow: 1,
    },
tagLabel:{
    '& .MuiChip-label':{
        fontSize : 'var(--uikit-typography-title5-fontSize)'
    }

},
shareButtonGhost:{
    '&.HvButton-secondaryGhost':{
        minWidth : "60px"
    }
},
hvCardHeader:{
    '&.HvCardHeader-root':{
        width : "100%",
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        display:"block"

    },
    '& .HvCardHeader-title':{
        width : "100%",
        overflow:"hidden",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        display:"block"
    }
}
}));
