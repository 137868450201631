/* Below code is AUth0 code */
import React, { useEffect, useRef, useState } from 'react';
import AppLayout from 'layout/AppLayout';
import Routes from 'routes/Routes';
import Spinner from 'components/spinner/Spinner';
import Banner from "components/banner/Banner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import LoginService from 'services/LoginService';
import { useStyles } from 'utils/theme';
import { withRouter, useLocation } from 'react-router';
import { useSelector } from "react-redux";
import { HvProvider } from "@hitachivantara/uikit-react-core";
import { config } from "config";
import { OktaAuth } from "@okta/okta-auth-js";
import { setTokenExpiry } from "redux/actions/loginActions";
import { doSsoLogin } from 'redux/actions/loginActions';
import { showBanner } from 'redux/actions/bannerActions';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { getAndSetTokenExpiry } from 'utils/common';
import _ from 'lodash';
import Unauthorized from 'components/Unauthorized';
import { LOGGED_IN_USER_INFO } from "constants/common";
import { LAB_AS_A_SERVICE, PREP_LAB_ONDEMAND, ON_DEMAND_LAB } from 'constants/labs';
const oktaAuth = new OktaAuth(config.oidc);
const loginServiceObj = new LoginService();
let intervalId;

function App({ history }) {
  let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
  let token = data ? JSON.parse(data) : {}
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);
  const virtualLabEndTime = useSelector(state => !_.isNull(state.labReducer.labInfo?.url_end_time) ? state.labReducer.labInfo?.url_end_time : 0)
  const schedule_labEndTime = useSelector(state => !_.isNull(state.labReducer.labInfo?.schedule_to) ? state.labReducer.labInfo?.schedule_to : "");
  const labType = useSelector(state => state.labReducer.labInfo?.lab_type);

  const location = useLocation();
  const isScPortal = location.pathname === '/scportal';


  const [renderAppRoutes, setRenderAppRoutes] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const ref = useRef();
  const expiryTime = useSelector((state) => state.loginReducer.expiryTime);
  const prevLabUrl = localStorage.getItem("LabUrl");
  ref.current = expiryTime;
  
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims, logout } = useAuth0();

  const getToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    setRenderAppRoutes(true);
    if (isAuthenticated) {
      let browserURL = window.location.href;
      if (browserURL.search("labId") > 0) {
        history.push(browserURL.split(window.location.origin)[1])
      }
    } else {
      if (!isAuthenticated) {
        let browserURL = window.location.href;
        if (browserURL.search("labId") > 0) {
          localStorage.setItem("LabUrl", browserURL.split(window.location.origin)[1])
          history.push("/");
        }
      } else {
        let browserURL = window.location.href;
        if (browserURL.search("labId") > 0) {
          getToken().then((accesstoken) => {
            getIdTokenClaims().then((token) => {
              const tokenExpirationTime = getAndSetTokenExpiry(token);
              dispatch(setTokenExpiry(tokenExpirationTime));
              ref.current = tokenExpirationTime;
              const payload = {
                encrypted_token: accesstoken,
                id_token: token.__raw
              }
              loginServiceObj.verifySsoToken(payload).then(result => {
                if (result.id && result.access_token) {
                  result['type'] = 'sso';
                  dispatch(doSsoLogin(result));
                  history.push(browserURL.split(window.location.origin)[1])
                }
              }).catch(() => {
                dispatch(showBanner({
                  payload: {
                    showBanner: true,
                    label: { message: "Error Occured while SSO Login. Please try again." },
                    variant: "error"
                  }
                }))
                history.push("/");
              });
            })
          });

        } else {
          history.push("/labs");
        }

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener('resize', handleResize);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAuthenticated, prevLabUrl])

  const isTokenAboutToExpire = () => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    console.log("token expiry", ref.current - currentTime)
    return ref.current - currentTime <= 60 // Check if the token is about to expire (e.g., 1 minute before expiry)
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = await getAccessTokenSilently()
      getIdTokenClaims().then((token) => {
        if (token) {
          const tokenExpirationTime = getAndSetTokenExpiry(token);
          dispatch(setTokenExpiry(tokenExpirationTime));
          ref.current = tokenExpirationTime;
          const payload = { encrypted_token: refreshToken, id_token: token.__raw };
          loginServiceObj.verifySsoToken(payload).then(result => {
            if (result.id && result.access_token) {
              result['type'] = 'sso';
              dispatch(doSsoLogin(result));
            }
          }).catch(() => {
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: "Error Occured while SSO Login. Please try again." },
                variant: "error"
              }
            }))
            history.push("/");
            logoutWithRedirect();

          });
        }
      })
    } catch (error) {
      console.error('Token refresh error:', error);
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error Occured while renewing token, Please login again." },
          variant: "error"
        }
      }))
      clearInterval(intervalId);
      history.push("/");
    }
  };
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: "/",
      }
    }).catch(() => {
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error while logout through SSO, Please login again." },
          variant: "error"
        }
      }))
    })
  // Check for token expiration every minute
  const tokenRefreshInterval = () => {
    if (isAuthenticated) {
      if (isTokenAboutToExpire()) {
        refreshAccessToken();
      }
    }
  }

  useEffect(() => {
    intervalId = setInterval(tokenRefreshInterval, 60000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated, ref]);

  useEffect(() => {
    let labEndTimer = "";
   
    const clearStopAllowedExtension = () => {
      localStorage.clear("stopAllowedExtension");
    };

    const setTimer = (endTime) => {
      const delay = new Date(endTime)?.getTime() - new Date().getTime();
      if (delay > 0) {
        labEndTimer = setTimeout(clearStopAllowedExtension, delay);
      }
    };

    if (labType === LAB_AS_A_SERVICE && !_.isEmpty(schedule_labEndTime)) {
      setTimer(schedule_labEndTime);
    }

    if ((labType === PREP_LAB_ONDEMAND || labType === ON_DEMAND_LAB) && !_.isEmpty(virtualLabEndTime)) {
      setTimer(virtualLabEndTime);
    }

    return () => clearTimeout(labEndTimer);

  }, [virtualLabEndTime, schedule_labEndTime]);

  return (

    <HvProvider uiKitTheme={theme}  cssBaseline='global'>
      {
        windowSize.width >= 768 ?
          <div className={classes.appContainer}>
            {/* {
              (isAuthenticated || token?.access_token && !isScPortal) && < InactivityMonitor timeout={IDEL_TIMEOUT_MINUTS * 60 * 1000} graceTime={IDEL_GRACE_TIME_SECONDS} />
            } */}
            <head><link
              href="https://fonts.googleapis.com/css?family=Open+Sans:400,600"
              rel="stylesheet"
            /></head>
            <Spinner />

            <Banner />

            <ToastContainer
              position="top-right"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
            />
            <AppLayout>
              {
                renderAppRoutes && <Routes />
              }
            </AppLayout>
          </div> : <Unauthorized />


      }


    </HvProvider>

  );

}
export default withRouter(App);
/* Auth0 Code ends here */