const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(() => ({
    root: {
      //   position: 'absolute',
      //   backgroundSize: 'contain !important',
    },
    responsive: {
      width: "65%",
      height: "auto",
      "@media(min-width:320px) and (max-width: 767px)": {
        display: "none",
      },
    },
    formContainer: {
      padding: "10% 32px 0 54px",
      textAlign: "center",
      width: "100%",
      background: "#FBFCFC 0% 0% no-repeat padding-box",
      opacity: "1",
    },
    title: {
      textAlign: "center",
    },
    link: {
      marginTop: "46px",
      font: "normal normal 600 14px/24px Open Sans",
      letterSpacing: "0px",
      opacity: 1,
      cursor: 'pointer'
    },
    or: {
      marginTop: "22px",
      font: "normal normal 600 14px/24px Open Sans",
      letterSpacing: "0px",
      color: "#6C6B6B",
      opacity: 1,
      cursor: 'pointer'
    },
    newUser: {
      marginTop: "46px",
      textAlign: "left",
      font: "normal normal normal 14px/24px Open Sans",
      letterSpacing: "0px",
      color: "#414141",
      opacity: 1,
    },
    label: {
      marginTop: "18px",
      "& .HvLabel-root": {
        textAlign: "left",
        font: "normal normal normal 14px/20px Open Sans",
        letterSpacing: "0px",
        color: "#414141",
        opacity: 1,
      },
      textAlign: "left",
      font: "normal normal normal 14px/20px Open Sans",
      letterSpacing: "0px",
      color: "#414141",
      opacity: 1,
      display: "block"
    },
    input: {
      background: '#FBFCFC 0% 0% no-repeat padding-box',
      border: '1px solid #6C6B6B',
      borderRadius: '2px',
      opacity: '1',
      width: '100%',
      height: '32px',
      display: 'block',
      padding: '6px 5px 5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      font:' normal normal normal 14px/24px Open Sans',
      letterSpacing: '0px',
      color: '#414141',
    },
    loginButtonContainer: {
      marginTop: "48px",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "10px",
    },
    loginButton: {
      width: "128px",
      alignSelf: "flex-end",
    },
    errormsg: {
      textAlign: "left"
    },
    underline: {
      "&:before": {
        borderBottom: "2px solid green",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "2px solid blue",
      },
      "&:after": {
        borderBottom: "3px solid purple",
      },
    },
    bannerlabel:{
      "& .HvBannerMessageContainer-message":{
        maxWidth : "100%"
      }
    }
  }));