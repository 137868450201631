import {
    Method_Activate_Lab_Url, Method_Running_Lab_Url, Method_Scheduled_Lab_Url, Method_New_Lab_Url, Method_Update, Method_Landing, INSTRUCTION_URL,
    AVAILABLE_SLOTS,
    SCHUDLE_LAB,
    SEND_MAIL,
    INVOKE_LAB_CLEANUP,
    INVOKE_LAB_CLEANUP_SETUP, AGREEMENT, USER_AGREEMENT, Feedback_URL,
    Feedback_Resource_Url,
    hardware_labs,
    VM_STATUS,
    LAB_EXTEND,
    HARDWARE_LAB_EXTEND,
    LAB_CURRENT_SLOT
} from "constants/labs";
import NetworkService from "./NetworkService";


class LabService {
    runningLabUrl = (params, urlParams) => {
        return NetworkService.get(`${params.tabIndex === 1 || params.tabIndex === 2 ? Method_New_Lab_Url : Method_Running_Lab_Url}?${urlParams}`).then((response) => response.data);
    }
    activateLabUrl = (payload) => {
        return NetworkService.post(Method_Running_Lab_Url, payload).then((response) => response.data);
    }
    terminateLab = (payload) => {
        return NetworkService.delete(Method_Activate_Lab_Url + `${payload.id}/`).then((response) => response.data);
    }
    disconnectLab = (payload, params) => {
        return NetworkService.patch(Method_Activate_Lab_Url + `${payload.id}/?${params}`).then((response) => response.data);
    }
    createLabUrl = async (payload) => {
        const response = await NetworkService.post(Method_New_Lab_Url, payload);
        return response.data;
    }
    editLabUrl = (labId, payload) => {
        return NetworkService.patch(`${Method_New_Lab_Url}${labId}/`, payload).then((response) => response.data);
    }
    getLabDetailsById = (labId) => {
        return NetworkService.get(`${Method_New_Lab_Url}${labId}/`).then((response) => response.data);
    }
    deleteLab = (labId) => {
        return NetworkService.delete(`${Method_New_Lab_Url}${labId}/`).then((response) => response.data);
    }
    undoDelete = (labId) => {
        const updatedMethodName = Method_Update.replace("{LAB_ID}", labId);
        return NetworkService
            .patch(updatedMethodName, { is_deleted: false })
            .then((response) => response.data);
    };
    landingPage = () => {
        return NetworkService.get(`${Method_Landing}/`).then((response) => response.data);
    }
    landingPageSearch = (searchValue) => {
        return NetworkService.get(`${Method_Landing}/?search=${searchValue}`).then((response) => response.data);
    }
    allLabUrl = async (urlParams) => {
        const response = await NetworkService.get(`${Method_New_Lab_Url}?${urlParams}`);
        return response.data;
    }
    runningLab = async (urlParams) => {
        const response = await NetworkService.get(`${Method_Running_Lab_Url}?${urlParams}`);
        return response.data;
    }
    scheduledLab = async (urlParams) => {
        const response = await NetworkService.get(`${Method_Scheduled_Lab_Url}?${urlParams}`);
        return response.data;
    }
    getCurrentScheduleLab = async(payload)=>{
        const response = await NetworkService.patch(`${LAB_CURRENT_SLOT}?${payload}`)
        return response;
    }
    getInstruction = async (urlParams) => {
        const response = await NetworkService.get(`${INSTRUCTION_URL}?${urlParams}`);
        return response;
    }
    setInstruction = async (payload) => {
        const response = await NetworkService.post(INSTRUCTION_URL, payload);
        return response;
    }
    updateInstruction = async (payload, id, labId) => {
        const response = await NetworkService.patch(`${INSTRUCTION_URL}${id}/?lab_id=${labId}`, payload);
        return response;
    }
    getAvailableSlots = async (urlParams) => {
        const response = await NetworkService.get(`${AVAILABLE_SLOTS}?${urlParams}`);
        return response;
    }
    bookSelectedSlot = async (payload) => {
        const response = await NetworkService.post(SCHUDLE_LAB, payload)
        return response;
    }
    sendMail = async (payload) => {
        const response = await NetworkService.post(SEND_MAIL, payload)
        return response;
    }
    cancelSelectedSlot = async (id) => {
        const response = await NetworkService.delete(`${SCHUDLE_LAB}${id}/`)
    }
    getSkyTapVm = async (url) => {
        const response = await NetworkService.get(url);
        return response
    }
    getInvokeLabCleanUp = async (params) => {
        const response = await NetworkService.get(`${INVOKE_LAB_CLEANUP}?${params}`);
        return response;
    }
    createLabCleanUpSetup = async (payload) => {
        const response = await NetworkService.post(INVOKE_LAB_CLEANUP_SETUP, payload)
        return response
    }
    getLabCleanUpSetup = async (params) => {
        const response = await NetworkService.get(`${INVOKE_LAB_CLEANUP_SETUP}?${params}`)
        return response
    }
    userAgreement = async () => {
        const response = await NetworkService.get(USER_AGREEMENT)
        return response;
    }
    acceptUserAgreement = async (payload) => {
        const response = await NetworkService.post(USER_AGREEMENT, payload)
        return response
    }
    getUserAgreement = async(params)=>{
        const response = await NetworkService.get(`${AGREEMENT}?${params}`)
        return response;
    }
    submitFeedback = async (payload) => {
        const response = await NetworkService.post(Feedback_URL, payload);
        return response.data
    }
    uploadFeedBackImage = async (payload) => {
        const response = await NetworkService.post(Feedback_Resource_Url, payload)
        return response
    }
    deleteFeedBackImage = async (resource_id) => {
        const response = await NetworkService.delete(`${Feedback_Resource_Url}${resource_id}/`)
        return response
    }
    getDependentLabs = async () => {
        const response = await NetworkService.get(`${hardware_labs}`)
        return response
    }
    getVmStatus = async (params) => {
        const response = await NetworkService.get(`${VM_STATUS}?${params}`);
        return response;
    }
    extendLab = async (params) => {
        const response = await NetworkService.patch(`${LAB_EXTEND}?${params}`);
        return response;
    }
    extendHardwareLab = async (params) => {
        const response = await NetworkService.patch(`${HARDWARE_LAB_EXTEND}?${params}`);
        return response;
    }
}
export default LabService;