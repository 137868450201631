import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
    HvButton, HvGrid, HvInput, HvTooltip, HvDropdown, HvTable,
    HvTableBody,
    HvTableCell,
    HvTableContainer,
    HvTableHead,
    HvTableHeader,
    HvTableRow,
    useHvData,
    hvTextColumn,
    HvTypography,
    useHvSortBy,
    useHvPagination,
    HvPagination,
    HvEmptyState,
    HvDialog,
    HvDialogActions,
    HvDialogTitle,
    HvDialogContent
} from '@hitachivantara/uikit-react-core';

import Footer from 'components/footer/Footer';
import FileUploadService from 'services/FileUploadService';
import { useDispatch } from 'react-redux';
import { buildQueryParams } from 'utils/common';
import LabMasterService from 'services/admin/masters/LabService';
import LabService from "services/LabService";
import { useStyles } from 'components/fileUpload/style'
import _ from "lodash";
import { showBanner } from 'redux/actions/bannerActions';
import { Ban, Close, Edit, Save } from '@hitachivantara/uikit-react-icons';
import ResourcePermission from './ResourcePermission';

const fileUploadServiceObj = new FileUploadService();
const ResourceSteps = props => {
    const { currentStep, mode, labData, masterUrl, history } = props
    const [labId] = useState(props?.labData?.id);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [fileData, setFileData] = useState([]);
    const [resourceUrl, setResourceUrl] = useState("");
    const [resourceName, setResourceName] = useState("");
    const [selectedresourceType, setSelectedResourceType] = useState("");
    const [selectKey, setSelectKey] = useState(0);
    const [totalResourceCount, setTotalResourceCount] = useState(0);
    const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
    const [resourceId, setResourceId] = useState(0);
    const [resourceType, setResourceType] = useState([
        { id: 1, label: 'Architecture Guide', value: 'Architecture Guide' },
        { id: 2, label: 'Blog', value: 'Blog' },
        { id: 3, label: 'Certification', value: 'Certification' },
        { id: 4, label: 'Customer Use Case', value: 'Customer Use Case' },
        { id: 5, label: 'Implementation Guide', value: 'Implementation Guide' },
        { id: 6, label: 'Interoperability', value: 'Interoperability' },
        { id: 7, label: 'Performance Report', value: 'Performance Report' },
        { id: 8, label: 'Reference Architecture', value: 'Reference Architecture' },
        { id: 9, label: 'Security', value: 'Security' },
        { id: 10, label: 'Sizing', value: 'Sizing' },
        { id: 11, label: 'Tool', value: 'Tool' },
        { id: 12, label: 'Video', value: 'Video' },
        { id: 13, label: 'White Paper', value: 'White Paper' }
    ]);

    const [editResource, setEditResource] = useState(false);

    const openResourceInNewTab = (url) => {
        var sanitizeUrl = encodeURI(url);
        window.open(sanitizeUrl, '_blank').focus();
    };

    const resourceUrlClick = (evt) => {
        if (_.isEmpty(resourceName) || _.isEmpty(resourceUrl)) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Please enter Resource Name and Resource URL." },
                    variant: "error"
                }
            }))
        } else {
            fileUploadServiceObj.uploadResourceUrl({
                "url": resourceUrl,
                "object_id": labId,
                "name": resourceName,
                "resources_type": selectedresourceType
            }).then(response => {
                if (response) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: "Resource  added." },
                            variant: "success"
                        }
                    }))

                    setResourceName("");
                    setResourceUrl("");
                    setSelectedResourceType("");
                    getALLResource();
                    setResourceType(resourceType.map((item) => {
                        return { ...item, selected: false }
                    }))
                    setSelectKey(prevKey => prevKey + 1);

                }
            }).catch(err => {
                console.log(err)
            })
        }

    }
    const removeSelectedResourceUrl = (resourceId) => {
        fileUploadServiceObj.deleteResourceUrl(resourceId).then(response => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Resource  Removed." },
                    variant: "success"
                }
            }))
            setResourceName("");
            setResourceUrl("");
            setSelectedResourceType("");
            getALLResource();
            setResourceType(resourceType.map((item) => {
                return { ...item, selected: false }
            }))
            getALLResource();
        }).catch(error => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: error && error.url && error.url.toString() },
                    variant: "error"
                }
            }))

        });
    }

    const handletagSave = () => {
        props.setNextStep(currentStep + 1);

    }


    const getColumns = () => [
        hvTextColumn({
            Header: "Resource Name",
            accessor: "name",
            cellType: "alpha-numeric"
        }),
        hvTextColumn({
            Header: "Resource Type",
            accessor: "resources_type",
            cellType: "alpha-numeric"
        }),

        hvTextColumn({
            Header: "Resource URL",
            accessor: "url",
            cellType: "alpha-numeric",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }}>
                            <HvTypography variant='link' style={{ cursor: "pointer" }} onClick={() => { openResourceInNewTab(cellData?.cell?.row?.original?.url) }}> {cellData?.value}</HvTypography>
                        </div>

                    );
                } else {
                    return (<></>)
                }

            },

        }),
        hvTextColumn({
            Header: "Permission",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }}>
                            <HvTypography variant='link' onClick={() => { renderPermission(cellData?.row?.original.id) }}>Add/Edit Permission</HvTypography>
                        </div>

                    );
                } else {
                    return (<></>)
                }

            },
        }),
        hvTextColumn({
            Header: "Actions",
            Cell: (props) => {
                const { cell, row, setRowState } = props;
                console.log("RowProps", cell)
                if (!_.isEmpty(cell)) {
                    return (
                        <>
                            <div style={{ display: "flex" }}>
                                {
                                    <HvTooltip title="Edit Resource"><HvButton variant='primaryGhost' onClick={(e) => {

                                    }}><Edit onClick={() => {
                                        setEditResource(!editResource);
                                        setResourceId(cell?.row?.original.id)
                                        setResourceName(cell?.row?.values?.name);
                                        setResourceUrl(cell?.row?.values?.url);
                                        setResourceType(resourceType.map((item) => {
                                            if (item.label === cell?.row?.values?.resources_type) {
                                                return { ...item, selected: true }
                                            } else {
                                                return { ...item, selected: false }
                                            }
                                        }))
                                        setSelectedResourceType(cell?.row?.values?.resources_type);
                                    }} /></HvButton></HvTooltip>
                                }

                                <HvTooltip title="Remove Resource"><HvButton variant='primaryGhost' onClick={() => {
                                    removeSelectedResourceUrl(cell?.row.original.id)
                                }}><Close /></HvButton></HvTooltip>
                            </div>
                        </>


                    );
                } else {
                    return (<></>)
                }

            },

        })

    ];

    const columns = useMemo(() => {
        return getColumns();
    }, []);

    const { getTableProps, getTableBodyProps, prepareRow, headers, page, gotoPage, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data: fileData,
                defaultColumn: {
                    Cell: ({ value }) => value ?? "—",
                },
                manualPagination: true,
                manualSortBy: true,
                disableCreateExpandButton: true,
                pageCount: totalResourceCount,


            },
            useHvSortBy,
            useHvPagination
        );

    useEffect(() => {
        gotoPage(0);
    }, [sortBy, gotoPage]);

    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );

    const rowRenderer = (pages) => {
        return pages.map((row, index) => {
            prepareRow(row);

            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                    >
                        {row.cells.map((cell) => (
                            <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </HvTableCell>
                        ))}
                    </HvTableRow>
                </React.Fragment>
            );
        });
    };

    useEffect(() => {
        if (labId > 0) {
            getALLResource();
        }

    }, [sortBy, pageIndex, pageSize])

    const getALLResource = () => {
        const resourceUrlParams = { object_id: labId, is_deleted: false, limit: pageSize, offset: pageSize * ((pageIndex + 1) - 1),is_admin:true,ordering:"-id" };
        fileUploadServiceObj.getResourceUrlByLabId(buildQueryParams(resourceUrlParams)).then(response => {
            if (response) {
                const { results, count } = response;
                if (results && results.length > 0) {
                    setFileData(results);
                    setTotalResourceCount(Math.ceil(count / pageSize));
                } else { setFileData([]) }

            }
        }).catch(error => {
            console.log(error)
        })
    }

    const renderPermission = (id) => {
        if (id) {
            setResourceId(id);
        }
        setOpenPermissionDialog(!openPermissionDialog)
    }

    const updateResource = () => {
        if (_.isEmpty(resourceName) || _.isEmpty(resourceUrl)) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Please enter Resource Name and Resource URL." },
                    variant: "error"
                }
            }))
        } else {
            fileUploadServiceObj.updateResource({
                "url": resourceUrl,
                "object_id": labId,
                "name": resourceName,
                "resources_type": selectedresourceType,
                "id": resourceId,
                "is_admin":true
            }, resourceId).then(response => {
                if (response) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: "Resource Updated." },
                            variant: "success"
                        }
                    }))

                    setResourceName("");
                    setResourceUrl("");
                    setSelectedResourceType("");
                    setResourceType(resourceType.map((item) => {
                        return { ...item, selected: false }
                    }))
                    getALLResource();
                    setEditResource(!editResource)
                    setSelectKey(prevKey => prevKey + 1);

                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    return (
        <div>
            <form autoComplete='on' onSubmit={(event) => {
                event.preventDefault();
                handletagSave()
            }}>
                <HvGrid container spacing={3}>
                    <HvGrid item xs={2} >
                        <HvInput
                            fullWidth
                            name="Name"
                            label="Name"
                            variant="outlined"
                            disabled={false}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.inputLabel

                            }}
                            onChange={(e) => {
                                setResourceName(e.target.value)

                            }}
                            value={resourceName}
                        >
                        </HvInput>
                    </HvGrid>
                    <HvGrid item xs={3} >
                        <HvInput
                            fullWidth
                            name="resource_url"
                            label="Resource Url"
                            variant="outlined"
                            disabled={false}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.inputLabel

                            }}
                            onChange={(e) => {
                                setResourceUrl(e.target.value)

                            }}
                            value={resourceUrl}
                        >
                        </HvInput>
                    </HvGrid>

                    <HvGrid item xs={3} >
                        <HvDropdown
                            key={selectKey}
                            classes={{ root: classes.textField }}
                            aria-label="With search"
                            showSearch
                            label="Type"
                            onChange={(valueObject) => {
                                setSelectedResourceType(valueObject?.value)

                            }}
                            values={resourceType}
                        />

                    </HvGrid>
                    <HvGrid item xs={2} style={{ marginTop: '42px' }} >
                        <HvButton
                            variant="primary"
                            component="label"
                            onClick={(e) => resourceUrlClick(e)}>
                            Add Resource Url
                        </HvButton>
                    </HvGrid>
                </HvGrid>
                <HvGrid container spacing={2} style={{ paddingTop: '20px' }}>
                    <HvGrid item xs={12}>
                        <HvTableContainer>
                            <HvTable
                                {...getTableProps({
                                    "aria-rowcount": fileData.length,
                                    caption: "Table Caption",
                                })}
                                style={{
                                    width: "100%",
                                    position: "relative"
                                }}
                            >
                                <HvTableHead>
                                    <HvTableRow>
                                        {headers.map((col) => (
                                            <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                                {col.render("Header")}
                                            </HvTableHeader>
                                        ))}
                                    </HvTableRow>
                                </HvTableHead>
                                <HvTableBody {...getTableBodyProps()}>
                                    {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                                </HvTableBody>
                            </HvTable>
                        </HvTableContainer>
                        <HvPagination
                            {...getHvPaginationProps()} />
                    </HvGrid>

                </HvGrid>
                < Footer handleSumbit={(event) => { event?.preventDefault() }} />
            </form >
            {
                openPermissionDialog ? <HvDialog fullscreen={true} open={openPermissionDialog} onClose={() => { setOpenPermissionDialog(!openPermissionDialog) }}>
                    <ResourcePermission
                        labData={labData}
                        mode={mode}
                        masterUrl={masterUrl}
                        labId={labId}
                        setNextStep={(value) => { setOpenPermissionDialog(value) }}
                        currentStep={currentStep}
                        history={history}
                        resourceId={resourceId}

                    /></HvDialog> : <></>
            }
            {
                editResource ? <>
                    <HvDialog
                        disableBackdropClick
                        open={editResource}
                        onClose={() => {
                            setResourceName("");
                            setResourceUrl("");
                            setSelectedResourceType("");
                            setResourceType(resourceType.map((item) => {
                                return { ...item, selected: false }
                            }))
                            getALLResource();
                            setEditResource(!editResource)
                        }}
                    >
                        <HvDialogTitle>Edit Resource</HvDialogTitle>
                        <HvDialogContent>
                            <form
                                id="Edit Resource"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    updateResource()
                                }}
                            >
                                <HvGrid container >
                                    <HvGrid item xs={12} >
                                        <HvInput
                                            fullWidth
                                            name="Name"
                                            label="Name"
                                            variant="outlined"
                                            disabled={false}
                                            classes={{ root: classes.textField }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputField,
                                                    root: classes.base,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.inputLabel

                                            }}
                                            onChange={(e) => {
                                                setResourceName(e.target.value)

                                            }}
                                            value={resourceName}
                                        >
                                        </HvInput>
                                    </HvGrid>
                                    <HvGrid item xs={12} >
                                        <HvInput
                                            fullWidth
                                            name="resource_url"
                                            label="Resource Url"
                                            variant="outlined"
                                            disabled={false}
                                            classes={{ root: classes.textField }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputField,
                                                    root: classes.base,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.inputLabel

                                            }}
                                            onChange={(e) => {
                                                setResourceUrl(e.target.value)

                                            }}
                                            value={resourceUrl}
                                        >
                                        </HvInput>
                                    </HvGrid>

                                    <HvGrid item xs={12} >
                                        <HvDropdown
                                            key={selectKey}
                                            id={"ResourceType"}
                                            aria-label="With search"
                                            showSearch
                                            label="Type"
                                            onChange={(valueObject) => {
                                                setSelectedResourceType(valueObject?.value)
                                            }}
                                            values={resourceType}
                                        />

                                    </HvGrid>
                                    <HvGrid item xs={6} >
                                        <HvTypography variant='link' onClick={() => { renderPermission(resourceId) }}>Add/Edit Permission</HvTypography>
                                    </HvGrid>
                                </HvGrid>
                            </form>
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton type="submit" form="Edit Resource">
                                Save
                            </HvButton>
                            <HvButton variant="secondaryGhost" onClick={() => {
                                setResourceName("");
                                setResourceUrl("");
                                setSelectedResourceType("");
                                setResourceType(resourceType.map((item) => {
                                    return { ...item, selected: false }
                                }))
                                getALLResource();
                                setEditResource(!editResource)
                            }}>
                                Cancel
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }
        </div >
    );
};


export default ResourceSteps;