import React, { useState, useEffect } from "react";
import {
  HvSwitch,
  HvTypography,
  HvSection,
  HvContainer,
  HvPagination,
  HvBox,
  HvDialog,
  HvHeader,
  HvDialogTitle,
  HvDialogContent
} from "@hitachivantara/uikit-react-core";
import { useHistory } from "react-router";
import { buildQueryParams } from "utils/common";
import FAQService from "services/admin/masters/FAQService";
import './faq.scss';
import { useStyles } from "./style";

const FAQs = () => {
  const [faqData, setFaqData] = useState([]);
  const [open, setOpen] = useState(true);
  const faqServiceObj = new FAQService();
  const pageSizeOptions = [5, 10, 20, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const history = useHistory();
  const classes = useStyles();
  const getAllFaqList = () => {
    let urlParams = { is_deleted: false };
    urlParams["limit"] = pageSize * ((pageSize + 1) - 1);
    urlParams["offset"] = 0;
    faqServiceObj
      .getAllFaqList(buildQueryParams(urlParams))
      .then((response) => {
        if (response) {
          setFaqData(response.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllFaqList();
  }, [pageSize]);


  return (
    <>
      <HvDialog open={open} fullscreen onClose={() => { setOpen(!open); history.goBack(); }}>
        <HvDialogTitle className={classes.title}>Frequently Asked Questions</HvDialogTitle>
        <HvDialogContent>{faqData.slice(pageSize * page, pageSize * (page + 1)).map((category) => (
          <HvContainer key={category} >
            <HvTypography variant="label">{category.name}</HvTypography>
            {category.questions.map((question) => (
              <HvBox>
                <HvSection
                  expandable
                  defaultExpanded={false}
                  id={question.question}
                  onToggle={function _a() { }}
                  title={<HvTypography>{question.question}</HvTypography>}
                >
                  <HvTypography>
                    <div className="content" style={{ width: "100%", height: "100%", padding: "10px" }}
                      dangerouslySetInnerHTML={{ __html: question.answer }}
                    />
                  </HvTypography>
                </HvSection>
                <HvBox style={{ height: "5px" }}></HvBox>
              </HvBox>
            ))}
          </HvContainer>
        ))}
        </HvDialogContent>

        <HvPagination
          id="pagination"
          pages={Math.ceil(faqData.length / pageSize)}
          page={page}
          canPrevious={page > 0}
          canNext={page < Math.ceil(faqData.length / pageSize) - 1}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          labels={{ pageSizeEntryName: "items" }}

        />

      </HvDialog>

    </>
  );
};

export default FAQs;
