import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HvTab, HvTabs, HvTypography, HvContainer, HvGrid } from "@hitachivantara/uikit-react-core";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { useStyles } from "./style";
import _ from "lodash";

LabContentInTab.propTypes = {
  instruction: PropTypes.any,
  videoArray: PropTypes.any,
  labName: PropTypes.any
};

function LabContentInTab(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const instruction = sessionStorage.getItem("Instruction");
  const videoArray = JSON.parse(sessionStorage.getItem("Video"));
  const classes = useStyles();
  const tabsName = [
    {
      index: 0,
      name: 'Text Instructions',
    },
    {
      index: 1,
      name: 'Video Instructions',
    }
  ];

  const renderInstruction = (instruction) => (
    <>
      <div
        style={{ overflow: "scroll", width: "100%" }} >
        {
          _.isNull(instruction) || _.isEmpty(instruction) || _.isUndefined(instruction) || instruction === "null" || instruction === "undefined" ?
            <HvTypography variant='selectedNavText'>No Text Instructions.</HvTypography>
            : <object data={instruction} type="text/html" aria-label="Url" width={"100%"} height={window.screen.height} />
        }

      </div>
    </>
  )

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const renderVideo = (videoArray) => {
    if (videoArray?.length > 0) {
      return (
        videoArray?.map((item) => {
          let urlParts = item?.split('/');
          const accountId = urlParts[3];
          const playerId = urlParts[4].split('_')[0];
          const videoId = urlParts[5].split('?videoId=')[1];
          return (
            <>
              <HvGrid item xl={4} lg={6} xs={12} sm={12} style={{paddingTop :"50px", paddingLeft:"50px"}}>
                <ReactPlayerLoader accountId={accountId} videoId={videoId} playerId={playerId} onFailure={onFailure}
                  onSuccess={onSuccess}
                  attrs={{
                    responsive: true,
                    autoplay: true,
                    muted: true,

                  }}
                />
              </HvGrid>

            </>
          )
        })
      )

    } else {
      return (
        <>
          <HvTypography variant="selectedNavText">No Video Instructions.</HvTypography>
        </>
      )
    }
  }

  const handleTabChange = (e, newValue) => setTabIndex(newValue);
  return (
    <>
      {

        _.isEmpty(videoArray[0]) && (instruction === "null" || instruction === "undefined") ? <></> :
          <div>
            <HvTypography variant="mTitle" style={{ paddingLeft: "80px", paddingTop: "10px" }}>{props.labName}</HvTypography>
            <HvTabs id="tabs-main" value={tabIndex} className={classes.tabs} style={{ paddingLeft: "80px", paddingTop: "10px" }} onChange={handleTabChange}>
              {
                tabsName.map((tab) => (
                  <HvTab id={tab.index} label={tab.name} value={tab.index} onChange={handleTabChange} />
                ))
              }
            </HvTabs>
            {
              tabIndex === 0 && renderInstruction(instruction)
            }
            <HvGrid container rowGap={2}>
              {
                tabIndex === 1 && renderVideo(videoArray)
              }
            </HvGrid>

          </div>
      }
    </>
  );
}

export default LabContentInTab;