import React, { useEffect, useRef, useState } from "react";
import { HvButton, HvDialog, HvDialogTitle, HvDialogContent, HvDialogActions, HvTab, HvTabs, HvTypography, HvContainer, HvStack, HvGrid } from "@hitachivantara/uikit-react-core";
import { Stop, Pause, Open, Home, EditNote, Time } from "@hitachivantara/uikit-react-icons";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
import SplitPane from "components/SplitBar";
import './styles.css'
import { useHistory } from "react-router";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import _ from "lodash";
import FeedbackDrawer from "containers/newHome/FeebBackDrawer";
import LabService from "services/LabService";
import { buildQueryParams } from "utils/common";
import { CO_CREATION_LAB, LAB_AS_A_SERVICE, ON_DEMAND_LAB, PREP_LAB_ONDEMAND, VM_STATUS_NOTE } from "constants/labs";
import Loader from "./Loader";
import { labInfo } from "redux/actions/labActions";
import { showBanner } from "redux/actions/bannerActions";



const tabsName = [
  {
    index: 0,
    name: 'Text Instructions',
  },
  {
    index: 1,
    name: 'Video Instructions',
  }
];



export const SplitView = ({
  handleInstructions,
  url,
  terminateLab,
  disconnectLab,
  labName,
  instruction,
  videoInstruction,
  downloadScreenshot,
  labId,
  extendLabTime,
  extendedLabTime,
  scPortalKey,
  allowHardWareExtenstion,
  showHarWareBanner,
  session_id
}) => {
  const [open, setOpen] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [isResizing, setIsResizing] = useState(true);
  const [instructionsOpenedInTab, setInstructionsOpenedInTab] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [openedTabs, setOpenedTabs] = useState([]);
  const [openFeedBackDrawer, setOpenFeedbackDrawer] = useState(false);
  const [vmStatus, setVmStatus] = useState("busy");
  const [progressBarValue, setProgressBarValue] = useState(10)
  const [progressBarStatus, setProgressBarStatus] = useState("inProgress");
  const [remainingTime, setRemainingTime] = useState();
  const [progressBarStatusMsg, setProgressBarStatusMsg] = useState(VM_STATUS_NOTE);
  const [showVirtualBanner, setShowVirtualBanner] = useState(false);
  const [extendHardware, setExtendHardware] = useState(showHarWareBanner)
  const extended_count = useSelector(state => !_.isNull(state.labReducer.labInfo?.extended_count) ? state.labReducer.labInfo?.extended_count : 0);
  const extended_time = useSelector(state => !_.isNull(state.labReducer.labInfo?.extended_minutes) ? state.labReducer.labInfo?.extended_minutes : 0);
  const virtualLabEndTime = useSelector(state => !_.isNull(state.labReducer.labInfo?.url_end_time) ? state.labReducer.labInfo?.url_end_time : 0)
  const labInfoObj = useSelector(state => state.labReducer.labInfo)
  const schedule_labEndTime = useSelector(state => !_.isNull(state.labReducer.labInfo?.schedule_to) ? state.labReducer.labInfo?.schedule_to : "");
  const labType = useSelector(state => state.labReducer.labInfo?.lab_type);
  const [launchTime, setLaunchTime] = useState(null);
  const [scheduledLabEndTime, setScheduledLabEndTime] = useState(null);
  const [labUrl, setLabUrl] = useState();
  const [vmKey, setVmKey] = useState(scPortalKey);

  const classes = useStyles();
  const instructionRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const totalDelaySetRef = useRef(false);
  const labServiceObj = new LabService();
  const videoArray = !_.isNull(videoInstruction) && !_.isEmpty(videoInstruction) ? videoInstruction?.split(',').map((value) => value.trim()) : [];
  const environment_id = useSelector(state => state.commonReducer.environment_id);

  const machineStatusRef = useRef();

  sessionStorage.setItem("Instruction", instruction);
  sessionStorage.setItem("Video", JSON.stringify(videoArray));
  const handleOnLoad = () => {
    dispatch(showLoader(false))
  }

  const openTerminateDialog = () => {
    setOpen(true);
  }

  const openDisconnectDialog = () => {
    setOpenDisconnect(true);
  }
  const stopResizing = () => { setIsResizing(false) };

  const startResizing = () => {
    setIsResizing(true);
  };

  const openInNewTab = () => {
    const newTab = window.open('', '_blank');
    const path = '/instructionInTab'; // Adjust the path as needed
    newTab.location.href = path;
    newTab.document.close();
    setOpenedTabs([...openedTabs, newTab]);
    setInstructionsOpenedInTab(true);
    setIsResizing(false);
  };


  const closeAllTabs = () => {
    openedTabs.forEach((tab) => tab.close());
    setOpenedTabs([]);
  };
  const closeTabsAndTerminate = () => {
    setOpen(false)
    closeAllTabs()
    terminateLab()
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  }

  const closeTabsAndDisconnect = () => {
    setOpenDisconnect(false)
    closeAllTabs()
    disconnectLab()
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  }


  const handleTabChange = (e, newValue) => setTabIndex(newValue);

  const renderInstruction = (instruction) => (
    <HvContainer className="custom-content" style={{ paddingLeft: "80px", paddingTop: "10px" }}>
      {
        _.isNull(instruction) || _.isEmpty(instruction) || _.isUndefined(instruction) ?
          <HvTypography variant="selectedNavText"> No Text Instructions.</HvTypography> :
          <div
            ref={instructionRef}
            style={{ overflow: "scroll", width: "100%" }} >
            <object data={instruction} aria-label="Url" type="text/html" width={"100%"} height={window.screen.height} onLoad={handleOnLoad} />
          </div>
      }

    </HvContainer>
  )

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const renderVideo = (videoArray) => {
    if (videoArray?.length > 0) {
      return (
        videoArray?.map((item) => {
          let urlParts = item?.split('/');
          const accountId = urlParts[3];
          const playerId = urlParts[4]?.split('_')[0];
          const videoId = urlParts[5]?.split('?videoId=')[1];
          if (videoId && accountId) {
            return (
              <>
               <HvContainer className="custom-content" style={{ paddingLeft: "80px", paddingTop: "10px" }}>
                  <ReactPlayerLoader accountId={accountId} videoId={videoId} playerId={playerId} onFailure={onFailure}
                    onSuccess={onSuccess}
                    attrs={{
                      responsive: true,
                      autoplay: true,
                      muted: true,

                    }}
                    className={classes.videoPlayer}
                  />
                </HvContainer >
              </>
            )
          }

        })
      )

    } else {
      return (
        <>
          <HvTypography variant="selectedNavText" style={{ alignSelf: "center", paddingLeft: "80px", paddingTop: "20px" }}>No Video Instructions.</HvTypography>
        </>
      )
    }
  }


  const handleFeedbacKState = (value) => {
    setOpenFeedbackDrawer(value)
  }

  const setProgressBarPercentage = (stage) => {
    switch (stage) {
      case 0:
        setProgressBarValue(30);
        setProgressBarStatusMsg("Please wait while the little elves create your lab.");
        break;
      case 1:
        setProgressBarValue(60);
        setProgressBarStatusMsg("The server is powered by a lemon and two electrodes.");
        break;
      case 2:
        setProgressBarValue(90);
        setProgressBarStatusMsg("Please wait while a larger hardware vendor in Santa Clara takes over the world");
        break;
      case "completed":
        setProgressBarValue(100);
        setVmStatus("running");
      default:
        setProgressBarValue(prev => prev + 1);
        break;
    }
  }
  const getVmStatus = () => {
    try {
      let params = {};
      params["environment_id"] = environment_id
      labServiceObj.getVmStatus(buildQueryParams(params)).then(response => {
        if (response) {
          if (!_.isNull(response.data?.status)) {
            setVmStatus(response?.data.status);
            setProgressBarPercentage(response?.data?.current_stage_index);
            if (!totalDelaySetRef.current) {
              const totalDelay = response.data.steps.reduce((sum, step) => sum + step.delay_after_finish_seconds, 0);
              setRemainingTime(totalDelay);
              totalDelaySetRef.current = true;
            }
            if (response.data.status === 'running') {
              setProgressBarStatus("completed")
              clearInterval(machineStatusRef.current);

            }
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }


  const handleLaunchLab = () => {
    setLaunchTime(new Date().getTime());
  };

  useEffect(() => {
    let params = {}
    params["lab"] = labId
    params["timezone"] = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
    params["pre_extend"] = true
    params["session_id"] = session_id
    if (labType === LAB_AS_A_SERVICE && allowHardWareExtenstion) {
      labServiceObj.extendHardwareLab(buildQueryParams(params)).then((response) => {
        if (!_.isEmpty(response) && response?.data?.pre_extended_status === true) {
          let labParams = {};
          labParams["lab"] = labId
          labServiceObj.getCurrentScheduleLab((buildQueryParams(labParams))).then((response) => {
            setScheduledLabEndTime(response.data?.current_slot[0]?.schedule_to);
            dispatch(labInfo({ ...labInfoObj, schedule_to: response.data?.current_slot[0]?.schedule_to }));
          }).catch((error) => {
            console.log(error);
            setScheduledLabEndTime("");
            setExtendHardware(false);
          })
        } else {
          console.log("lab already extended");
          setExtendHardware(false);
          setScheduledLabEndTime("");
        }
      }).catch((error) => {
        console.log("lab already extended");
        setExtendHardware(false);
        setScheduledLabEndTime("");
        dispatch(showBanner({
          payload: {
            showBanner: false,
            label: { message: error?.response?.data?.message },
            variant: "error",
          },
        }))
      })

    }
  }, [allowHardWareExtenstion])

  useEffect(() => {
    setLabUrl(url);
    setVmKey(scPortalKey);
    setExtendHardware(showHarWareBanner);
  }, [url, extendedLabTime, vmKey, scPortalKey, showHarWareBanner, allowHardWareExtenstion]);

  useEffect(() => {
    let labEndTimer = "";
    if (vmStatus === "running" && (extended_count > 0 || labType === LAB_AS_A_SERVICE)) {
      handleLaunchLab();
    }

    const clearStopAllowedExtension = () => {
      localStorage.clear("stopAllowedExtension");
    };

    const setTimer = (endTime) => {
      const delay = new Date(endTime)?.getTime() - new Date().getTime();
      if (delay > 0) {
        labEndTimer = setTimeout(clearStopAllowedExtension, delay);
      }
    };

    if (labType === LAB_AS_A_SERVICE && !_.isEmpty(schedule_labEndTime)) {
      setTimer(schedule_labEndTime);
    }

    if (labType === PREP_LAB_ONDEMAND || labType === ON_DEMAND_LAB) {
      setTimer(virtualLabEndTime);
    }

    return () => clearTimeout(labEndTimer);

  }, [virtualLabEndTime, scheduledLabEndTime, vmStatus]);



  useEffect(() => {
    if (!_.isEmpty(scheduledLabEndTime)) {
      const endTime = new Date(scheduledLabEndTime);
      const bannerTime = new Date(endTime.getTime() - 10 * 60000); // 10 minutes before end time
      const currentTime = new Date().getTime();
      const timeUntilBanner = bannerTime.getTime() - currentTime;
      if (timeUntilBanner > 0) {
        console.log(`Time to check extened slot ${bannerTime.toLocaleTimeString()}`);
        const timer = setTimeout(() => {
          extendLabTime()
        }, timeUntilBanner);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
      } else {
        console.log('Banner will show immediately as less than 10 minutes remain');
        setExtendHardware(true); // If less than 10 minutes remain, show the banner immediately
      }
    } else {
      if (launchTime !== null && vmStatus === "running" && (labType === ON_DEMAND_LAB || labType === PREP_LAB_ONDEMAND) && virtualLabEndTime !== null) {
        const endTime = new Date(virtualLabEndTime);
        const bannerTime = new Date(endTime.getTime() - 10 * 60000); // 10 minutes before end time
        const currentTime = new Date().getTime();
        const timeUntilBanner = bannerTime.getTime() - currentTime;
        if (timeUntilBanner > 0) {
          console.log(`Banner will show at ${bannerTime.toLocaleTimeString()}`);
          const timer = setTimeout(() => {
            setShowVirtualBanner(true);
          }, timeUntilBanner);
          return () => clearTimeout(timer); // Cleanup the timer on component unmount
        } else {
          setShowVirtualBanner(true); // If less than 10 minutes remain, show the banner immediately
        }
      }
    }

  }, [vmStatus, launchTime, scheduledLabEndTime]);

  useEffect(() => {
    machineStatusRef.current = setInterval(() => getVmStatus(machineStatusRef), 5000);
    return () => clearInterval(machineStatusRef.current);
  }, [])


  const extendLab = () => {
    setShowVirtualBanner(!showVirtualBanner);
    extendLabTime();
  }

  return (
    <div key={vmKey}>
      {
        instructionsOpenedInTab ?
          <SplitPane split="vertical" minSize={0}
            maxSize={-100}
            defaultSize={"95%"} allowResize={false}>
            <div onMouseEnter={stopResizing} style={{ height: "100%" }}>
              {
                vmStatus === "running" ? <object data={labUrl?.replace("/v2/vms/", "/vms/")} type="text/html" aria-label="Url" width={"100%"} height={"100%"} onLoad={handleOnLoad} onMouseEnter={stopResizing} /> :
                  remainingTime > 0 ? <Loader progressBarValue={progressBarValue} progressBarStatusMsg={progressBarStatusMsg} progressBarTime={remainingTime} progressBarStatus={progressBarStatus} /> : <></>
              }
            </div>
            <div>
              <div style={{ height: window.screen.height, display: "flex" }}>
                <div style={{ width: "5%", background: "#1775E0 0% 0% no-repeat", height: window.screen.height, position: "fixed", display: "flex" }}>
                  <div>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openTerminateDialog} title="Terminate" ><Stop style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    {labType === CO_CREATION_LAB ? <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openDisconnectDialog} title="Disconnect"><Pause style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton> : <></>}
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Open Instruction in Tab"}><Open style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} onClick={openInNewTab} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"FeedBack"} ><EditNote onClick={() => { setOpenFeedbackDrawer(!openFeedBackDrawer) }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    {/* <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={()=>{()=>{extendLabTime()}}} title="Extend Lab"><Time style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }}/></HvButton> */}
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Go to Home Page"}><Home style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} onClick={() => { disconnectLab(); history.push("/labs") }} /></HvButton>
                  </div>
                </div>
                <div style={{ paddingLeft: "70px", flex: '1' }} onMouseEnter={stopResizing} onChange={stopResizing}>
                  <HvDialogTitle>{labName}</HvDialogTitle>
                  <HvTabs id="tabs-main" value={0} className={classes.tabs} style={{ padding: '0 10px' }}>
                    {
                      tabsName.map((tab) => (
                        <HvTab id={tab.index} label={tab.name} />
                      ))
                    }

                  </HvTabs>
                  <HvDialogContent style={{ paddingTop: 20, maxHeight: '75vh' }}>
                    <div ref={instructionRef} dangerouslySetInnerHTML={{ __html: instruction }} />
                  </HvDialogContent>
                </div>
              </div>
            </div>
          </SplitPane> :
          <>
            <SplitPane split="vertical" minSize={0}
              maxSize={-100}
              defaultSize={"50%"} allowResize={isResizing} onDragFinished={stopResizing} onResizerClick={startResizing} step={2} primary="second" >
              <div onMouseEnter={stopResizing} style={{ height: "100%" }}>
                {
                  vmStatus === "running" ? <object data={labUrl?.replace("/v2/vms/", "/vms/")} type="text/html" aria-label="Url" width={"100%"} height={"100%"} onLoad={handleOnLoad} onMouseEnter={stopResizing} /> :
                    remainingTime > 0 ? <Loader progressBarValue={progressBarValue} progressBarStatusMsg={progressBarStatusMsg} progressBarTime={remainingTime} progressBarStatus={progressBarStatus} /> : <></>
                }
              </div>
              <div>
                <div style={{ height: window.screen.height, overflow: "auto" }} >
                  <HvStack style={{ background: "#1775E0 0% 0% no-repeat", height: window.screen.height, position: "absolute" }} direction="column">
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openTerminateDialog} title="Terminate" ><Stop style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    {labType === CO_CREATION_LAB ? <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openDisconnectDialog} title="Disconnect"><Pause style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton> : <></>}
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Open Instruction in Tab"}><Open onClick={openInNewTab} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"FeedBack"} ><EditNote onClick={() => { setOpenFeedbackDrawer(!openFeedBackDrawer) }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    {/* <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={() => { extendLabTime(false); }} title="Extend Lab"><Time style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton> */}
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Go to Home Page"}><Home onClick={() => { disconnectLab(); history.push("/labs") }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                  </HvStack>

                  <div onMouseEnter={stopResizing} onChange={stopResizing}>

                    <HvTypography variant="title2" style={{ paddingLeft: "80px", paddingTop: "10px" }}>{labName}</HvTypography>
                    <HvTabs id="tabs-main" value={tabIndex} className={classes.tabs} style={{ paddingLeft: "80px", paddingTop: "10px" }} onChange={handleTabChange}>
                      {
                        tabsName.map((tab) => (
                          <HvTab id={tab.index} label={tab.name} value={tab.index} onChange={handleTabChange} />
                        ))
                      }
                    </HvTabs>
                    {
                      tabIndex === 0 && renderInstruction(instruction)
                    }
                    
                      {
                        tabIndex === 1 && renderVideo(videoArray)
                      }
                  

                  </div>
                </div>
              </div>
            </SplitPane>

          </>
      }

      {
        open ? <div>
          <HvDialog
            disableBackdropClick
            open={open}
            onClose={() => setOpen(false)}
            id="terminate"
            classes={{
              closeButton: classes.closeButton
            }}>
            <HvDialogTitle variant="warning">Terminate Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              If you have just launched the lab then please wait for 5 mins and then terminate.
              Terminate lab will end the lab. You need to run the lab again.
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={closeTabsAndTerminate} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => setOpen(false)}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
      {
        openDisconnect ? <div >
          <HvDialog
            disableBackdropClick={true}
            open={openDisconnect}
            id="disconnect"
            classes={{
              closeButton: classes.closeButton
            }}
          >
            <HvDialogTitle variant="warning">Disconnect Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              Disconnect lab will pause the Lab.You can access it from Running Labs section.
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={closeTabsAndDisconnect} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => setOpenDisconnect(false)}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
      {
        openFeedBackDrawer ? <div>
          <FeedbackDrawer labId={labId} openDrawer={openFeedBackDrawer} handleDrawerState={handleFeedbacKState} />
        </div> : <></>
      }
      {
        showVirtualBanner ? <div >
          <HvDialog
            disableBackdropClick={true}
            open={showVirtualBanner}
            id="ExtendLab"
            classes={{
              closeButton: classes.closeButton
            }}
          >
            <HvDialogTitle variant="warning">Extend Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              Your lab is about to end, Do you want to extend the Lab for {extended_time} minutes
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={extendLab} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => { setShowVirtualBanner(false); setLaunchTime(null); }}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
      {
        extendHardware ? <div >
          <HvDialog
            disableBackdropClick={true}
            open={extendHardware}
            id="ExtendLab"
            classes={{
              closeButton: classes.closeButton
            }}
          >
            <HvDialogTitle variant="warning">Extend Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              Your scheduled lab session will end in less than 10 minutes. Would you like to extend it?
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={() => { setExtendHardware(false); extendLabTime(true); setScheduledLabEndTime("") }} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => { setExtendHardware(false); setLaunchTime(null); setScheduledLabEndTime("") }}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
    </div>


  );
};
