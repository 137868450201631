import { HvButton, HvTypography, HvBox, HvContainer, HvBanner } from "@hitachivantara/uikit-react-core";
import { useForm } from "hooks/useForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { backdoorLogin } from "redux/actions/loginActions";
import { useStyles } from "./style";
import { useAuth0 } from "@auth0/auth0-react";
import StorageService from "services/StorageService";
import { doSsoLogin } from 'redux/actions/loginActions';
import { showBanner } from 'redux/actions/bannerActions';
import LoginService from "services/LoginService";
import _ from "lodash";
import { showLoader } from "redux/actions/commonActions";
import { generateState } from "@okta/okta-auth-js";
import { useSelector } from "react-redux";
import { getAndSetTokenExpiry } from "utils/common";
import { setTokenExpiry } from "redux/actions/loginActions";
import CommonService from "services/CommonService";
import { Info } from "@hitachivantara/uikit-react-icons";

const prevLabUrl = localStorage.getItem("LabUrl");
const storageServiceObj = new StorageService();
const loginServiceObj = new LoginService();
const commonServiceObj = new CommonService();


const Login = () => {
  const [bannerMessage, setBannerMessage] = useState();
  const [openBanner, setOpenBanner] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const expiryTime = useSelector((state) => state.loginReducer.expiryTime);
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, logout, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const storedState = localStorage.getItem("ssoState");
      if (isAuthenticated && storedState) {
        const isValidState = validateState(storedState);
        if (isValidState) {
          authenticateOkta();
        } else {
          console.error("Invalid state detected!");
        }

      }
    };

    handleAuthCallback();
  }, [isAuthenticated]);


  useEffect(()=>{
    commonServiceObj.getBannerMessage().then((response)=>{
      if(response){
          setBannerMessage(response?.message);
          setOpenBanner(!openBanner);
      }
  }).catch((error)=>{
      console.log(error);
  })
  },[])
  

  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    data, // access to the form data
    errors, // includes the errors to show
  } = useForm({
    // the hook we are going to create
    initialValues: {
      // used to initialize the data
      email: "",
      password: "",
    },
    validations: {
      // all our validation rules go here
      email: {
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
          message: "Email is not valid",
        },
      },
      password: {
        required: {
          value: true,
          message: "Password is required",
        },
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/]).{8,15}$/,
          message: "Password length should be  8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        }
      },
    },
    onSubmit: async () => {
      await dispatch(backdoorLogin(data));
      const userInfo = storageServiceObj.getUserData()
      if (!_.isEmpty(userInfo)) {
        history.push("/labs");
      }
    },
  });

  const validateState = (storedState) => {
    return typeof storedState === 'string' && storedState.trim() !== '';
  };

  const getIdTokenForAuthenticate = async (access_token) => {
    getIdTokenClaims().then((token) => {
      const tokenExpirationTime = getAndSetTokenExpiry(token);
      dispatch(setTokenExpiry(tokenExpirationTime));
      dispatch(showLoader(true));
      const payload = {
        encrypted_token: access_token,
        id_token: token.__raw
      }
      loginServiceObj.verifySsoToken(payload).then(
        result => {
          if (result.id && result.access_token) {
            result['type'] = 'sso';
            dispatch(doSsoLogin(result));
            if (!_.isEmpty(prevLabUrl)) {
              history.push(prevLabUrl)
            } else {
              history.push("/labs");
            }
          }

          dispatch(showLoader(false))
        }
      ).catch(() => {
        dispatch(showBanner({
          payload: {
            showBanner: true,
            label: { message: "Error Occured in Authenticate Okta User, Please login again." },
            variant: "error"
          }
        }))
        logoutWithRedirect();
      })
    });
  }

  const authenticateOkta = async () => {
    const token = await getAccessTokenSilently()
    getIdTokenForAuthenticate(token);
  }

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_returnTo,
        clientId : process.env.REACT_APP_OKTA_CLIENT_ID   
      }
    }).catch(() => {
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error while logout through SSO, Please login again." },
          variant: "error"
        }
      }))
    })

  const redirectToSSO = async () => {
    const state = generateState()
    localStorage.setItem("ssoState", state);
    await loginWithRedirect({
      appState: state
    }).catch(() => {
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error Occured in SSO login." },
          variant: "error"
        }
      }))
    })
  };
  const redirectToRegistrationPage = () => history.push("/registration");
  const redirectToForgotPassword = () => history.push("/forgot-password");

  return (
    <>
      {
        <div style={{ display: "flex" }}>
          {
            bannerMessage ? <HvBanner  open={openBanner} offset={64} customIcon={<Info color="base_dark" />} className={classes.bannerlabel} label={bannerMessage} onClose={()=>{setOpenBanner(!openBanner)}}></HvBanner> :<></>
          }
          <HvContainer component="main" maxWidth="xs" style={{ display: "contents" }}>
            <img
              src="/assets/images/login.png"
              alt="Login"
              className={classes.responsive}
            />
            <div className={classes.formContainer}>
              <HvTypography variant="mTitle" className={classes.title}>
                Welcome,
              </HvTypography>
              <HvTypography variant="mTitle" className={classes.title}>
                Please click on the link below to Sign In.
              </HvTypography>
              <HvTypography
                variant="link"
                className={classes.link}
                onClick={redirectToSSO}
              >
                Sign in with SSO
              </HvTypography>

              {
                process.env.REACT_APP_CUSTOM_NODE_ENV === "development" || process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" ? <> <HvTypography variant="normalText" className={classes.or}>
                  or
                </HvTypography>
                  <form onSubmit={handleSubmit} className={classes.form} noValidate method="get" action="">
                    <HvBox>
                      <label htmlFor="username" className={classes.label}>
                        Username
                      </label>
                      <input
                        type="text"
                        className={classes.input}
                        value={data.email || ""}
                        onChange={handleChange("email")
                        }
                        placeholder="Enter Text"
                      />
                      {errors?.email ? (
                        <HvTypography
                          variant="selectedNavText"
                          className={classes.errormsg}
                        >
                          {errors?.email}
                        </HvTypography>
                      ) : null}
                    </HvBox>
                    <HvBox>
                      <label htmlFor="password" className={classes.label}>
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className={classes.input}
                        onChange={handleChange("password")}
                        autoComplete="off"
                        placeholder="Enter Text"
                      />
                      {errors?.password ? (
                        <HvTypography
                          variant="selectedNavText"
                          className={classes.errormsg}
                        >
                          {errors?.password}
                        </HvTypography>
                      ) : null}
                    </HvBox>
                    <HvBox className={classes.loginButtonContainer}>
                      <HvTypography
                        variant="highlightText"
                        className={classes.or}
                        onClick={redirectToForgotPassword}
                      >
                        Reset Password
                      </HvTypography>
                      <HvButton
                        type="submit"
                        category="primary"
                        className={classes.loginButton}
                      //   className={clsx(classes.submit, classes.sentenceCase)}
                      >
                        Login
                      </HvButton>
                    </HvBox>
                  </form>
                  <HvBox style={{ display: "flex" }}>
                    <HvTypography variant="highlightText" className={classes.newUser}>
                      New User?&nbsp;&nbsp;
                    </HvTypography>
                    <HvTypography
                      variant="link"
                      className={classes.link}
                      onClick={redirectToRegistrationPage}
                    >
                      Register
                    </HvTypography>
                  </HvBox></> : <></>
              }

            </div>
          </HvContainer>
        </div>
      }
    </>


  );
};

export default Login;
/*  Auth0 Code End */
