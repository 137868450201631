/* eslint-disable no-useless-computed-key */
import React, { useState, useRef } from 'react';
import { HvAvatar, HvButton, HvHeader, HvHeaderActions, HvHeaderBrand, HvHeaderNavigation, HvDropdown, HvVerticalNavigationTree, HvTypography } from "@hitachivantara/uikit-react-core";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Menu } from "@hitachivantara/uikit-react-icons";
import { useSelector } from 'react-redux';
import NestedMenu from './headerDropdown';
import { HvDrawer } from '@hitachivantara/uikit-react-core';
import { LOGGED_IN_USER_INFO } from "constants/common";
import SearchBar from "components/searchBar";
import { debounce } from "lodash";
import { withRouter } from 'react-router';
import _ from 'lodash';


const NewHeader = ({ location, history }) => {
  const fromSessionUSerInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO);
  const inputRef = useRef("")
  const userInfo = JSON.parse(fromSessionUSerInfo);
  const [open, setOpen] = useState(false);
  const themeSelector = useSelector((state) => state.themeReducer.theme);
  const showFullScreen = useSelector((state) => state.commonReducer.showFullScreen);
  const drawerWidth = "50%";
  const drawerHeight = "60%";
  const userName = userInfo && userInfo.full_name;
  const userLoggedIn = userInfo?.id > 0  ? true : false;
  const hasCustomer = userInfo?.groups?.map(item => item.name === "Customers");
  const isCustomer = hasCustomer?.includes(true);

  let firstName = '';
  let lastName = '';
  if (userName) {
    let fullNameArr = userName.split(' ');
    firstName = fullNameArr && fullNameArr[0];
    lastName = fullNameArr && fullNameArr[1];
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'absolute',
      height: '62px !important',
      borderTop: 'none',
      boxShadow: '0px 2px 12px #4141411f',
      opacity: 1,
      zIndex: 2,
      display: showFullScreen ? "none" : "flex",
      '  & .HvHeaderMenuBar-menubar': {
        height: 'auto',
        alignItems: 'center',
        ["@media (max-width:1163px)"]: { paddingLeft: '140px' },
      },
      ' & .HvHeaderMenuItem-selectedItem': {
        borderTop: 'none',
        borderBottom: `4px solid ${themeSelector === "dawn" ? "#313131" : '#fbfcfc'}`

      },
      ' & .HvHeaderMenuItem-root:hover': {
        backgroundColor: 'unset'
      },
      ' & .HvHeaderMenuItem-root:hover:focus-within > [role=button]': {
        backgroundColor: 'unset'
      },
      ' & .HvHeaderMenuItem-button:focus': {
        backgroundColor: 'unset'
      },
      ' & .HvHeaderMenuItem-root:hover > [role=button]': {
        backgroundColor: 'unset'
      },
      ' & .HvTypography-normalText': {
        textAlign: 'left',
        font: 'normal normal normal 14px/24px Open Sans',
        letterSpacing: '0px',

        opacity: '1'
      },
      ' & .HvTypography-selectedNavText': {
        textAlign: 'left',
        font: 'normal normal normal 600 14px/24px Open Sans',
        letterSpacing: '0px',
        color: `${themeSelector === "dawn" ? ' #313131 ' : '#fbfcfc'}`,
        opacity: '1'
      },
      ' & .HvHeaderMenuItem-button': {
        padding: '16px 20px'
      },
      '& .HvHeaderMenuBar-list': {
        paddingLeft: "395px",
        ["@media (max-width:1163px)"]: { paddingLeft: '40px' },
        ["@media (max-width:1920px)"]: { paddingLeft: '1000px' },
        ["@media (max-width:1280px)"]: { paddingLeft: '400px' },
      },
      "& .HvDrawer-background": {
        background: "rgba(204, 206, 208, 0) "
      },
      "& .HvHeader-header > *:not(nav)": {
        zIndex: 3
      }
    },
    logo: {
      width: '8rem',
      cursor: 'pointer',
      paddingLeft: '10px',
      height: '30px'
    },
    brand: {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
      },
      "& .HvTypography-highlightText": {
        fontWeight: 'unset'
      }
    },
    avatar: {
      cursor: 'pointer'
    },
    dropdownMenuProperties: {
      top: "3px",
      zIndex: "1",
      width: "100%",
      background: "#FBFCFC00 0% 0% no-repeat padding-box",
      opacity: "9",
      "& .HvBaseDropdown-header": {
        border: "1px solid #2064B4 !important",

      },
      "& .HvBaseDropdown-arrow": {
        border: "1px solid #2064B4 !important",
        background: "#414141 0 % 0 % no - repeat padding - box",
        opacity: "1"
      },

      "& .HvTypography-body": {
        textAlign: "left",
        font: "normal normal 600 14px/20px Open Sans",
        letterSpacing: "0px",
        color: "#2064B4",
        opacity: "1",
        padding: "5px"
      },
      "& .HvDropdownList-listContainer": {
        background: "#FBFCFC 0% 0% no-repeat padding-box",
        boxShadow: "0px 2px 12px #4141411F",
        borderRadius: "0px 0px 2px 2px",
        opacity: "1",
        width: "199px",
        left: "-22px",
        top: "32px",
        position: "absolute",
        height: "90px",
        overflow:"visible"
      },
      "& .HvListContainer-root":{
        overflow:"visible"
      },
      "& .HvTypography-normalText": {
        textAlign: "left",
        font: "normal normal 600 14px/20px Open Sans",
        letterSpacing: "0px",
        color: "#2064B4",
        opacity: "1",
        padding: "5px"
      }

    },
    dropdownBox: {
      position: "fixed",
      width: "290px",
      top: "13px",
      left: "880px"
    },
    drawerPaper: {
      width: `calc(100% - ${drawerWidth})`,
      height: `calc(100% - ${drawerHeight})`,
      overflow: "unset",
      position: "relative",
      "& .HvIconBase-s": {
        display: "none"
      },
      transitionTimingFunction: "cubic-bezier(0, 0.7, 1.0, 0.1) !important",
      transition: "width 3s !important",
      display: "block",
      top: "64px"

    },

    drawerContent: {
      flex: 1,
      overflow: "auto",

    },

    mobileMenuFonts: {
      '& .HvTypography-normalText': {
        color: "#414141",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.02em"
      },
      "& .HvTypography-placeholderText": {
        color: "#1d1d1d",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.02em"
      }

    }
  }));

 
  const classes = useStyles();
  const theme = useTheme();
  let isLoginPage = location?.pathname === '/email-login' || location.pathname === "/" || location.pathname === "/instructionInTab"  

  const [selected, setSelected] = useState("home");
  const [showProfileMenu, setProfileMenu] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = (e, selectedItem) => {
    history.push(selectedItem?.url)
    setSelected(selectedItem.id);
    setOpen(false)
  };
  const redirectToHome = () => history.push('/labs');
  const redirectToLoginPage = () => history.push('/email-login');
  const toggleMenuProfile = () => setProfileMenu(!showProfileMenu);

  const redirectTo = (url) => {
    history.push(url);
  }


  const changeHandler = (event) => {
    const value = event.target.value ? event.target.value : inputRef.current?.value
     debounceSearchLabData(value);
    
  };
  const debounceSearchLabData = debounce(value => {
    history.push({
      pathname: '/labs',
      state: {
        searchedString: value,
      }
    });
  }, 1000);

  return (

    <HvHeader position="relative" className={classes.root}>
      {!isMdUp && (
        <div>
          <HvButton style={{ width: 32, height: 32 }} icon >
            <Menu onClick={() => setOpen(true)} />
            <HvDrawer
              id="drawer-test"
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}

              onChange={handleChange}
              hideBackdrop
            >
              <HvVerticalNavigationTree style={{
                paddingTop: "32px"
              }}
                data={[]}
                className={classes.mobileMenuFonts}
                onChange={handleChange}
                collapsible
              />
            </HvDrawer>
          </HvButton>
        </div>
      )
      }
      {
        isMdUp ? <><HvHeaderBrand className={classes.brand} logo={<img className={classes.logo} src={themeSelector === "dawn" ? "/assets/images/logo.svg" : "/assets/images/logo.svg"} alt={"Hitachi Logo"} onClick={redirectToHome} />} name={<HvTypography variant='body'>Automated Labs Online</HvTypography>} /></> : <></>
      }
      {
        isMdUp && (
          !isLoginPage ? <></> :
            <>
              <HvHeaderNavigation className={classes.root} data={[]} selected={selected} onClick={handleChange} />
            </>
        )
      }
      {
        isMdUp && (!isLoginPage ? <><SearchBar inputRef={inputRef} changeHandler={changeHandler} /></> : <></>)
      }
      {showProfileMenu && userLoggedIn && <NestedMenu userInfo={userInfo} setProfileMenu={toggleMenuProfile} />}

      {
        !isLoginPage
        && 
        <HvHeaderActions>
          {isMdUp && (
            !isLoginPage && !isCustomer &&
            <>
              <HvDropdown
                className={classes.dropdownMenuProperties}
                id="dropdown8"
                aria-label="No default"
                hasTooltips
                disablePortal
                values={[
                  {
                    id: "LabISVRequest",
                    label: "Co-Creation Request",
                    url: "/isv-request"
                  }
                ]}
                onChange={(item) => redirectTo(item.url)}
                placeholder={"Co-Create Instructions"} />
            </>
          )}

          {!userLoggedIn ? <HvAvatar className={classes.avatar} backgroundColor="acce1" size="MD" onClick={redirectToLoginPage} /> :
            <>
              <HvAvatar className={classes.avatar} backgroundColor="secondary" size="MD" onClick={toggleMenuProfile}>
                {firstName?.substr(0, 1)}{lastName?.substr(0, 1)}
              </HvAvatar>
            </>
          }
        </HvHeaderActions>
      }

    </HvHeader >
  );

}

export default withRouter(NewHeader);