import { theme } from "@hitachivantara/uikit-react-core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  globalAction: {
    "& .HvGlobalActions-wrapper": {
      justifyContent: "center",
    },
    "& .HvGlobalActions-actions": {
      marginLeft: "100px",
    },
  },
  readOrHide: {
    color: "#1874CD",
    cursor: "pointer",
    textDecoration: "underline",
  },
  closeButton: {
    display: "none",
  },
});
export default useStyles;
